import {React, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import VoiceCard from './VoiceCard';
import AnaImage from '../../images/Ana.PNG'
import AmberImage from '../../images/Amber.PNG';
import AriaImage from '../../images/Aria.PNG';
import AshleyImage from '../../images/Ashley.PNG'
import CoraImage from '../../images/Cora.PNG';
import ElizabethImage from '../../images/Elizabeth.PNG'
import JennyImage from '../../images/Jenny.PNG'
import AndrewImage from '../../images/Andrew.PNG'
import RyanImage from '../../images/Ryan.PNG';
import ChristopherImage from '../../images/Christopher.PNG';
import BrianImage from '../../images/Brian.PNG';
import BrandonImage from '../../images/Brandon.PNG'




import Amber from '../../audio/AmberOutput.mp3'
import Ana from '../../audio/AnaChildOutput.mp3'
import Andrew from '../../audio/AndrewOutput.mp3'
import Aria from '../../audio/AriaOutput.mp3'
import Ashley from '../../audio/AshleyOutput.mp3'
import Brandon from '../../audio/BrandonOutput.mp3'
import Brian from '../../audio/BrianOutput.mp3'
import Christopher from '../../audio/ChristopherOutput.mp3'
import Cora from '../../audio/CoraOutput.mp3'
import Elizabeth from '../../audio/ElizabethOutput.mp3'
import Jenny from '../../audio/JennyMultiLingualOutput.mp3'
import Ryan from '../../audio/RyanMultiLingualOutput.mp3'
import ReactGA from 'react-ga4';


export const AiVoices = () => {
    const textToSpeak = 'Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do”'

    const textSpoken = 'Alice was beginning to get very tired of sitting by her sister on the bank, and of having nothing to do: once or twice she had peeped into the book her sister was reading, but it had no pictures or conversations in it, "and what is the use of a book," thought Alice, "without pictures or conversations?”'
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "FeaturesPage" });
    }, [location]);
    const voices = [
        {
            voiceName: 'Amber',
            spokenText: textToSpeak,
            imageUrl: `${AmberImage}`,
            audioUrl: `${Amber}`,
        },
        {
            voiceName: 'Ana (Child)',
            spokenText: textToSpeak,
            imageUrl: `${AnaImage}`,
            audioUrl: `${Ana}`,
        }
        ,
        {
            voiceName: 'Brian',
            spokenText: textToSpeak,
            imageUrl: `${BrianImage}`,
            audioUrl: `${Brian}`,
        }
        ,
        {
            voiceName: 'Aria',
            spokenText: textToSpeak,
            imageUrl: `${AriaImage}`,
            audioUrl: `${Aria}`,
        }
        ,
        {
            voiceName: 'Ashley',
            spokenText: textToSpeak,
            imageUrl: `${AshleyImage}`,
            audioUrl: `${Ashley}`,
        }
        ,
        {
            voiceName: 'Brandon',
            spokenText: textToSpeak,
            imageUrl: `${BrandonImage}`,
            audioUrl: `${Brandon}`,
        }
        ,
        {
            voiceName: 'Andrew',
            spokenText: textToSpeak,
            imageUrl: `${AndrewImage}`,
            audioUrl: `${Andrew}`,
            
        }
        ,
        {
            voiceName: 'Christopher',
            spokenText: textToSpeak,
            imageUrl: `${ChristopherImage}`,
            audioUrl: `${Christopher}`,
        }
        ,
        {
            voiceName: 'Cora',
            spokenText: textToSpeak,
            imageUrl: `${CoraImage}`,
            audioUrl: `${Cora}`,
        }
        ,
        {
            voiceName: 'Elizabeth',
            spokenText: textToSpeak,
            imageUrl: `${ElizabethImage}`,
            audioUrl: `${Elizabeth}`,
        }
        ,
        {
            voiceName: 'Jenny',
            spokenText: textToSpeak,
            imageUrl: `${JennyImage}`,
            audioUrl: `${Jenny}`,
        }
        ,
        {
            voiceName: 'Ryan',
            spokenText: textToSpeak,
            imageUrl: `${RyanImage}`,
            audioUrl: `${Ryan}`,
        }
    ];

    return (
        <div className='aivoices'>
            <div>
                <h3>AI VOICES</h3>
            </div>
            <div>
                <h4>{textSpoken}</h4>
            </div>
            <div  style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>            
            {
                voices.map((voice,index) => (
                    <VoiceCard 
                    key={index}
                    voiceName={voice.voiceName}
                    spokenText={voice.spokenText}
                    imageUrl={voice.imageUrl}
                    audioUrl={voice.audioUrl}
                    />
                ))
            }
            </div>
        </div>
    )
}