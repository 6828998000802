import React, {useState,useEffect} from 'react'
import {containerStyle,buttonStyle,buttonContainerStyle,MainTextStyle,textareaStyle,dropDownStyle}  from "../../styles";
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useMutation } from '@tanstack/react-query';
// import { userProfileAPI } from '../../apis/user/usersAPI';
import StatusMessage from '../Alert/StatusMessage';
import { generateTTSApi } from '../../apis/tts/tts';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';


export default function GenerateVoice() {
    const [volume, setVolume] = useState(100); // Initial volume (between 0 and 100)
  const [text,setText] = useState('')
  
  const [audioUrl,setAudioUrl] = useState('')

  const [voice,setVoice] = useState('en-US-AriaNeural');
  const [rate,setRate] = useState(1);
  const [pitch,setPitch] = useState('default');
  
  // const {isLoading,isError,data,error} = useQuery({
  //   queryFn: userProfileAPI,
  //   queryKey: ["profile"]
  // });
  const mutation = useMutation({mutationFn:generateTTSApi})

  // if(isLoading){
  //   return <StatusMessage type="loading" message="Loading Please wait..." />
  // }
  // if(isError){
  //   return <StatusMessage type="error" message={error?.response?.data?.message} />
  // }
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "GenerateVoicePage" });
  }, [location]);
  const handleChangeVolume = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
  };

  const handleChangeText = (e) => {
    setText(e.target.value);
  }

  const handleConvert = async () => {
    console.log({text,volume,rate,pitch,voice});
    // try{
    //   const response = await fetch('http://localhost:8000/convert',{
    //     method: 'POST',
    //     headers: {
    //       'Content-Type':'application/json',
    //     },
    //     body: JSON.stringify({ text,volume,voice,rate,pitch }),
    //   })
    //   if(!response.ok){
    //     throw new Error('Failed to convert text to speech');
    //   }
    //   const data = await response.json();
    //   setAudioUrl(data.audioUrl);
    //   console.log(data.audioUrl);
    // }
    // catch(err){
    //   console.error('Error converting text to speech',err.message);
    // }
    mutation.mutate({ text, volume, rate, pitch, voice }, {
      onSuccess: (data) => {
        setAudioUrl(data.audioUrl);
        console.log("audioUrl:", data.audioUrl);
      },
    });
    ReactGA.event({
      category: 'Button Click',
      action: 'Clicked on Generate Button',
      label: 'Generate Voice',
    });
  }
  
  console.log("mutation: ",mutation);
  const handlePlay = () => {
    console.log("audio Url play: ",audioUrl);
    if(audioUrl){
      const audio = new Audio(audioUrl);
      audio.play();
    }
  }
  

  const handleChange = (event) => {
    setVoice(event.target.value);
  };
  const handleChangeRate = (event) => {
    setRate(event.target.value);
  };
  const handleChangePitch = (event) => {
    setPitch(event.target.value);
  };
  return (
    <>
    <div>
    <h1 style={MainTextStyle}>ADVANCED TEXT TO SPEECH CONVERTER</h1>
    {/* Loading */}
    {
      mutation?.isPending && (
         <StatusMessage type="loading" message="Loading Please wait..." />
      )
    }
    {/* success */}
    {
      mutation?.isSuccess && (
         <StatusMessage type="success" message="Voice Generation Successful" />
      )
    }
    {/* error */}
    {
      mutation?.isError && (
         <StatusMessage type="error" message={mutation?.error?.response?.data?.message} />
      )
    }
    <div className='flex mt-3'>
      <div className='mr-2 mb-2'>
        <span className='text-sm font-semibold bg-green-200 px-4 py-2 rounded-full'>
          Plan: FREE
        </span>
      </div>
      <div className='mr-2 mb-2'>
        <span className='text-sm font-semibold bg-green-200 px-4 py-2 rounded-full'>
          Credit: FREE
        </span>
      </div>
    </div>
        <div style={containerStyle}>
          <textarea 
            placeholder='Enter text to Convert'
            style={textareaStyle}
            value={text}
            rows="18" cols="100"
            onChange={handleChangeText}
          />
          <br />
          <div style={buttonContainerStyle}>
          <InputLabel>Voice Volume</InputLabel>
          <Slider value={volume} onChange={handleChangeVolume} min={0} max={100} defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
          
          

          <InputLabel>Voice Rate</InputLabel>
        <Slider min={0.5} max={2} step={0.1} value={rate} onChange={handleChangeRate} aria-label="Default" valueLabelDisplay="auto" />
          

        <FormControl fullWidth style={dropDownStyle}>
            <InputLabel id="demo-simple-select-label">Voice Pitch</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pitch}
              label="Voice Pitch"
              onChange={handleChangePitch}
            >
              <MenuItem value={'x-low'}>x-low</MenuItem>
              <MenuItem value={'low'}>low</MenuItem>
              <MenuItem value={'medium'}>medium</MenuItem>
              <MenuItem value={'high'}>high</MenuItem>
              <MenuItem value={'x-high'}>x-high</MenuItem>
              <MenuItem value={'default'}>default</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth style={dropDownStyle}>
            <InputLabel id="demo-simple-select-label">Voice</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={voice}
              label="Voice"
              onChange={handleChange}
            >
              <MenuItem value={'en-US-AriaNeural'}>Aria</MenuItem>
              <MenuItem value={'en-US-JennyMultilingualNeural'}>Jenny</MenuItem>
              <MenuItem value={'en-US-AmberNeural'}>Amber</MenuItem>
              <MenuItem value={'en-US-AnaNeural'}>Ana</MenuItem>
              <MenuItem value={'en-US-AshleyNeural'}>Ashley</MenuItem>
              <MenuItem value={'en-US-CoraNeural'}>Cora</MenuItem>
              <MenuItem value={'en-US-ElizabethNeural'}>Elizabeth</MenuItem>
              <MenuItem value={'en-US-RyanMultilingualNeural'}>Ryan</MenuItem>
              <MenuItem value={'en-US-AndrewNeural'}>Andrew</MenuItem>
              <MenuItem value={'en-US-BrandonNeural'}>Brandon</MenuItem>
              <MenuItem value={'en-US-BrianNeural'}>Brian</MenuItem>
              <MenuItem value={'en-US-ChristopherNeural'}>Christopher</MenuItem>
              
            </Select>
          </FormControl>
          
            <button style={buttonStyle} onClick={handleConvert}>Convert to Speech</button>
            
          </div>
          <br />
          </div>
          {console.log("audio Url: ",audioUrl)}
          {
           
           audioUrl && (
              <>
                <button style={buttonStyle} onClick={handlePlay}>Play Audio</button>
                <br />
                <audio controls>
                  <source src={audioUrl} type='audio/mp3' />
                  <a href={audioUrl}> Download audio </a>
                  Your Browser does not support audio element.
                </audio>
                <a href={audioUrl} download="downloaded-audio">Download Audio</a>
              </>
            )
          }
    </div>
    </>
)}


///////////////////////////////////////////////////////////////////


// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { Link } from "react-router-dom";

// const BlogPostAIAssistant = () => {
//   const [generatedContent, setGeneratedContent] = useState("");

//   // Formik setup for handling form data
//   const formik = useFormik({
//     initialValues: {
//       prompt: "",
//       tone: "",
//       category: "",
//     },
//     validationSchema: Yup.object({
//       prompt: Yup.string().required("A prompt is required"),
//       tone: Yup.string().required("Selecting a tone is required"),
//       category: Yup.string().required("Selecting a category is required"),
//     }),
//     onSubmit: (values) => {
//       // Simulate content generation based on form values
//       console.log(values);
//       setGeneratedContent(`Generated content for prompt: ${values.prompt}`);
//     },
//   });

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-900 flex justify-center items-center p-6">
//       <div className="bg-white rounded-xl shadow-xl overflow-hidden max-w-2xl w-full p-6">
//         <h2 className="text-3xl font-extrabold text-gray-800 mb-6 text-center">
//           AI Blog Post Generator
//         </h2>

//         {/* Static display for Plan and Credits */}
//         {/* ... */}

//         {/* Form for generating content */}
//         <form onSubmit={formik.handleSubmit} className="space-y-4">
//           {/* Prompt input field */}
//           <div>
//             <label
//               htmlFor="prompt"
//               className="block text-gray-700 text-sm font-semibold mb-2"
//             >
//               Enter a topic or idea
//             </label>
//             <input
//               id="prompt"
//               type="text"
//               {...formik.getFieldProps("prompt")}
//               className="px-4 py-2 w-full border rounded-md focus:ring-2 focus:ring-indigo-500"
//               placeholder="Enter a topic or idea"
//             />
//             {formik.touched.prompt && formik.errors.prompt && (
//               <div className="text-red-500 mt-1">{formik.errors.prompt}</div>
//             )}
//           </div>

//           {/* Tone selection field */}
//           <div>
//             <label
//               htmlFor="tone"
//               className="block text-gray-700 text-sm font-semibold mb-2"
//             >
//               Select Tone
//             </label>
//             <select
//               id="tone"
//               {...formik.getFieldProps("tone")}
//               className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
//             >
//               <option value="">Choose a tone...</option>
//               <option value="formal">Formal</option>
//               <option value="informal">Informal</option>
//               <option value="humorous">Humorous</option>
//             </select>
//             {formik.touched.tone && formik.errors.tone && (
//               <div className="text-red-500 mt-1">{formik.errors.tone}</div>
//             )}
//           </div>

//           {/* Category selection field */}
//           <div>
//             <label
//               htmlFor="category"
//               className="block text-gray-700 text-sm font-semibold mb-2"
//             >
//               Select Category
//             </label>
//             <select
//               id="category"
//               {...formik.getFieldProps("category")}
//               className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
//             >
//               <option value="">Choose a category...</option>
//               <option value="technology">Technology</option>
//               <option value="health">Health</option>
//               <option value="business">Business</option>
//             </select>
//             {formik.touched.category && formik.errors.category && (
//               <div className="text-red-500 mt-1">{formik.errors.category}</div>
//             )}
//           </div>

//           {/* Submit button */}
//           <button
//             type="submit"
//             className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//           >
//             Generate Content
//           </button>
//           {/* Link to view history */}
//           <Link to="/history">View history</Link>
//         </form>

//         {/* Display generated content */}
//         {generatedContent && (
//           <div className="mt-6 p-4 bg-gray-100 rounded-md">
//             <h3 className="text-lg font-semibold text-gray-800 mb-2">
//               Generated Content:
//             </h3>
//             <p className="text-gray-600">{generatedContent}</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default BlogPostAIAssistant;

