import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import speechifylogo from "../../assets/speechifylogo.png"
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
// import { useMutation } from "@tanstack/react-query";
// import { loginAPI } from "../../apis/user/usersAPI";
// import { initializeDefaultMetadata } from './AuthOFunction';
import ReactGA from 'react-ga4';


const navigation = [
  { name: "Home", href: "/" },
  { name: "Features", href: "/features" },
  { name: "Pricing", href: "/plans" },
  { name: "About", href: "/about" },
];

export default function PublicNavbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { loginWithRedirect} = useAuth0();

  function handleClick() {
    // Track a custom event
    ReactGA.event({
      category: 'Button Click',
      action: 'Clicked on Sign Up',
      label: 'SignUp',
    });
    // Continue with your button's click handler
    loginWithRedirect();
  }
  //const accessToken = getAccessTokenSilently();

// var options = {
//   method: 'POST',
//   url: 'https://dev-6guuvvahnv2ulnih.us.auth0.com/api/v2/users',
//   headers: {authorization: `Bearer ${accessToken.__raw}`, 'content-type': 'application/json'},
//   data: {
//     email: user?.email,
//     user_metadata: {hobby: 'surfing'},
//     app_metadata: {plan: 'full'}
//   }
// };

// axios.request(options).then(function (response) {
//   console.log(response.data);
// }).catch(function (error) {
//   console.error(error);
// });

  return (
    <>
      <div className="text-gray-700 body-font border-b border-gray-200">
        <header>
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              {/* Logo */}
              <Link to="/" className=" text-gray-900 w-10 h-10">
                <img src={speechifylogo} alt="speechifyhub" />
              </Link>
              <h3 className="ml-3 font-semibold leading-6  text-gray-900">SpeechifyHub</h3>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5  text-gray-900"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-sm font-semibold leading-6  text-gray-900"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <Link
               onClick={handleClick}
                className="text-sm font-semibold leading-6  text-gray-900 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Log in <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
              <div className="flex items-center justify-between">
                <a href="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="py-6">
                    <Link
                    onClick={() => loginWithRedirect()}
                     
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                    >
                      Log in
                    </Link>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
    </>
  );
}