import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {loadStripe} from '@stripe/stripe-js'
import { QueryClient,QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from './components/AuthContext/AuthContext';
import { Auth0Provider } from '@auth0/auth0-react';
import { Elements } from '@stripe/react-stripe-js';
import ReactGA from 'react-ga4';

const stripePromise = loadStripe("pk_test_51OSxIFSImSPwSA3dd3Lo0AThNLvgS1fZI8n7hFnaOAvWPXB8ajBnuJt6LxWysKEwrnIHUBbAyd4hRCyat43BsFm700sqbSymRi")
const options = {
  mode: 'payment',
  currency: 'usd',
  amount: 2024,
}
ReactGA.initialize('G-R10F4GYCZR');
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
  <Auth0Provider
  domain="dev-6guuvvahnv2ulnih.us.auth0.com"
  clientId="foutuLD3GxuxiNktlUz6sWUr8xRJB8dQ"
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Elements stripe={stripePromise} options={options}>
            <App />
          </Elements>
        
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
