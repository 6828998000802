import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const VoiceCard = ({voiceName,spokenText,imageUrl,audioUrl}) => {
    return(
    <Card sx={{ width: 350, margin: 1 }}>
      <CardMedia component="img" height="300" image={imageUrl} alt={voiceName} sx={{ borderRadius: '16px 16px 0 0' }} />
      <CardContent>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{voiceName}</Typography>
        <Typography variant="body2">{spokenText}</Typography>
        
        <audio controls>
            <source src={audioUrl} type='audio/mp3'/>
                Your Browser does not support the audio tag.
                </audio>
            
      </CardContent>
    </Card>
    )
}
export default VoiceCard;