import './App.css';
import React from 'react'

import { pageStyle} from './styles';

import { AiVoices, Contact} from "./components/pages";
import {BrowserRouter, Route, Routes } from "react-router-dom";

// import Registration from './components/Users/Register';
// import Login from './components/Users/Login';
// import Dashboard from './components/Users/Dashboard';
import Home from './components/Home/Home';
import PrivateNavbar from './components/Navbar/PrivateNavbar';
import PublicNavbar from './components/Navbar/PublicNavbar';
// import { useAuth } from './components/AuthContext/AuthContext';
// // import AuthRoute from './components/AuthRoute/AuthRoute';
import GenerateVoice from './components/VoiceGeneration/GenerateVoice';
import Plans from './components/Plans/Plans';
// import CheckoutForm from './components/StripePayment/CheckoutForm';
// import PaymentSuccess from './components/StripePayment/PaymentSuccess';
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  // const {isAuthenticated} = useAuth();
  const { isAuthenticated } = useAuth0();

  return (
    <>
    <BrowserRouter>
    
      <div className="App" style={pageStyle}>
          {
            isAuthenticated?<PrivateNavbar />:<PublicNavbar />
          }
          <Routes>
            
            {/* <Route path="/register" element={<Registration />} /> */}
            {/* <Route path="/login" element={<Login />} /> */}
            {/* <Route path="/dashboard" element={
              
              <Dashboard />
            
            } /> */}
            <Route path="/generatevoice" element={
            // <AuthRoute>
            isAuthenticated && <GenerateVoice />
            // </AuthRoute>
            } />
            <Route path="/plans" element={<Plans />} />
            {/* <Route path="/success" element={
              
              <PaymentSuccess />
            
            } /> */}
            
            <Route path="/features" element={<AiVoices />} />
            <Route path="/contact" element={<Contact />} />
            
            {/* <Route 
              path="/checkout/:plan"
              element={
                <AuthRoute>
                  <CheckoutForm />
                </AuthRoute>
            }/> */}
            <Route path="/" element={<Home />} />
          </Routes>
      </div>  
    </BrowserRouter>
    </>  
  );
}

export default App;
