import axios from "axios";
// --------REGISTRATION-----------

export const generateTTSApi = async ({text,volume,rate,voice,pitch}) => {
    const response = await axios.post("https://tts-backend-sdk2.onrender.com/api/v1/tts/generate",{
        text,volume,rate,pitch,voice
    },
    {
        withCredentials: true
    }
    );
    return response?.data;
};