
export const pageStyle = {
    backgroundColor: '#F9F7F7',
    minHeight: '100vh',
  };

export const containerStyle = {
    boxShadow: '8px 8px 8px 8px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
    display: 'flex',
    justifyContent: 'space-around', // Adjust as needed
    padding: '16px', // Add padding for spacing
    marginRight: '130px',
    marginLeft: '130px',
    borderRadius: '20px',
    width: 'auto',
    height: 'auto',
    backgroundColor: '#FFFFFF',
  };

export const buttonStyle = {
    
    fontSize: '18px', // Increase the font size as needed
    padding: '12px 20px', // Adjust padding for a larger button
    backgroundColor: '#112D4E', // Set the background color
    color: 'white', // Set the text color
    border: 'none',
    borderRadius: '8px', // Add border-radius for rounded corners
    cursor: 'pointer',
    fontFamily: 'Poppins, sans-serif',
    marginTop: '20px',
  };
export const buttonContainerStyle = {
    boxShadow: '3px 3px 3px 3px rgba(0, 0, 0, 0.1)',
    border: '2px solid #112D4E',
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    borderRadius: '20px',
    width: 'auto',
    height: 'auto',
    backgroundColor: '#FFFFFF',
  };
  export const MainTextStyle = {
    fontSize: '36px', // Adjust the font size as needed
    fontFamily: 'Poppins, sans-serif',
    color: '#112D4E'
  }

  export const textareaStyle = {
    fontSize: '16px', // Adjust the font size as needed
    fontFamily: 'Poppins, sans-serif', // Change the font family as needed
    border: '2px solid #112D4E', // Change the border color as needed
    resize: 'none',
  };
  
  export const dropDownStyle = {
    marginTop: '15px'
  }