import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";
import { FiLogOut } from "react-icons/fi";
import speechifylogo from "../../assets/speechifylogo.png"
import { Link} from "react-router-dom";
// import { useMutation } from "@tanstack/react-query";
// import { logoutAPI } from "../../apis/user/usersAPI";
// import { useAuth } from "../AuthContext/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";
// import { initializeDefaultMetadata } from './AuthOFunction';
import ReactGA from 'react-ga4';

// const user = {
//   name: "Tom Cook",
//   email: "tom@example.com",
// };
const navigation = [
  { name: "Features", href: "/features", current: true },
  { name: "Contact Us", href: "/contact", current: true },
  { name: "Pricing", href: "/plans", current: true },
];
const userNavigation = [{ name: "Sign out", href: "#" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PrivateNavbar() {
  const { logout ,user} = useAuth0();
  console.log("user data: ",user);

  function handleClick() {
    // Track a custom event
    ReactGA.event({
      category: 'Button Click',
      action: 'Clicked on LogOut',
      label: 'LogOut',
    });
    // Continue with your button's click handler
    logout({ logoutParams: { returnTo: window.location.origin } })
  }
  //useEffect(() => {
  //   const initializeMetadata = async () => {
  //     console.log("isauthenticated:",isAuthenticated);
  //     console.log("user:",user);
  //   //   const auth0Client = await createAuth0Client({
  //   //     domain: 'dev-6guuvvahnv2ulnih.us.auth0.com',
  //   //     client_id: 'foutuLD3GxuxiNktlUz6sWUr8xRJB8dQ',
  //   // });
  //     // console.log("!user.user_metadata:",!user.user_metadata);
  //     if (isAuthenticated && user && !user.user_metadata) {
  //       console.log("here");
  //       try {
  //         // Get Auth0 client instance
  //        // const auth0Client = await getAccessTokenSilently();

  //         // Call the function to initialize default metadata
  //         await initializeDefaultMetadata(auth0Client, user);
  //         console.log("Initialized default metadata");
  //       } catch (error) {
  //         console.error('Error getting Auth0 client:', error);
  //       }
  //     }
  //   };

  //   initializeMetadata();
  // }, [isAuthenticated, user, getAccessTokenSilently]);
  
  // const {logout} = useAuth();
  // const mutation = useMutation({mutationFn: logoutAPI})
  //handle logout
  // const handleLogout = () => {
  //   // try {
  //   //     await mutation.mutateAsync();
  //   //     logout(); // Make sure this function handles the client-side token removal
  //   // } catch (error) {
  //   //     console.error("Logout failed", error);
  //   //     // Handle error, if any
  //   // }
  //   logout({ logoutParams: { returnTo: window.location.origin } })
  // }
  return (
    <Disclosure as="nav" className="bg-gray-900">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    {/* <span className="absolute -inset-0.5" /> */}
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  {/* logo */}
                  <Link to="/" className="text-white w-10 h-10">
                <img src={speechifylogo} alt="speechifyhub" />
              </Link>
                </div>
                <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "rounded-md px-3 py-2 text-sm font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                  <div className="text-white w-10 h-10">
                    <h1>Welcome</h1>
                    <h2>{user.nickname}</h2>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link
                    to="/generatevoice"
                    className="relative animate-bounce inline-flex items-center gap-x-1.5 rounded-md bg-purple-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Generate content
                  </Link>
                  
                  <button
                    onClick={handleClick}
                    type="button"
                    className="ml-2 relative inline-flex items-center gap-x-1.5 rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    <FiLogOut className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  <Menu as="div" className="relative ml-3">
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5 sm:px-6">
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {user.name}
                  </div>
                  <div className="text-sm font-medium text-gray-400">
                    {user.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2 sm:px-3">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}